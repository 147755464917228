import React, { useState, useRef } from 'react';
import { ImageComponent } from '../ImageComponent';
import apiimg from 'services/apiimg';

/*
props
  foto=
  setFoto=
*/

function CAMERACOMPONENT({ 
                          foto: Afoto, 
                          setFoto: AsetFoto, 
                          titulo: Atitulo='', 
                          class: Aclasse='',
                          width: Awidth=512,
                          height: Aheight=512,
                          index: indice=-1,
                          campo='',
                          nomedoarquivo,
                          diretorio
                        }) {
  const [stream, setStream] = useState(null);
  const [image, setImage] = useState(null);
  const [timestamp, setTimestamp] = useState(Date.now());
  const videoRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const uploadImage = (base64Image) => {    
    const ldata = {
      nomearq: nomedoarquivo,
      conteudo: base64Image.substring(22)
    };    
    apiimg.post(`${diretorio}/post_img.php`, ldata)
      .then(response => {
        if (response.status === 201) {
          console.log('imagem gravada com sucesso');
          setTimestamp(Date.now());
        } else {
          console.error('Error uploading image:', response.data.erro ?? '');
        }      
      }).catch (error => {
        console.error('Error uploading image:', error);
      });
  };

  const setNovaImagem = (imgbase64) => {
    const newTimestamp = Date.now();
    if (nomedoarquivo) {     
      if (indice === -1) {
        AsetFoto(`/${diretorio}/${nomedoarquivo}`);
      } else {
        AsetFoto(`/${diretorio}/${nomedoarquivo}`, indice, campo);
      }
    } else {
      if (AsetFoto) {
        if (imgbase64) {
          if (indice === -1) {
            AsetFoto(imgbase64);
          } else {
            AsetFoto(imgbase64, indice, campo);
          }
        }
      }
    }
    setImage(imgbase64);
    setTimestamp(newTimestamp);
    uploadImage(imgbase64);
  };

  const cleanClick = () => {
    if (indice === -1) {
      AsetFoto('');
    } else {      
      AsetFoto('', indice, campo);
    }
    setImage(null);
    setTimestamp(Date.now());
  };

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = mediaStream;
      setStream(mediaStream);
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
  };

  const captureImage = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = canvas.toDataURL('image/png');
    stopCamera();
    setImage(imageData);
    setNovaImagem(canvas.toDataURL('image/png'));
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const MAX_WIDTH = Awidth;
        const MAX_HEIGHT = Aheight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        setImage(canvas.toDataURL('image/png'));
        setNovaImagem(canvas.toDataURL('image/png'));
      };
      img.src = e.target.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='container cameraimagem'>
      <div style={{ marginBottom: '10px' }}>
        <button type='button' className='btn btn-outline-warning ms-2'
          onClick={startCamera}>
          <span><i className="fas fa-camera"></i></span></button>
        <button type='button' className='btn btn-outline-info ms-2'
          onClick={captureImage} disabled={!stream}>
          <span><i className="fas fa-portrait"></i></span></button>
        <button className="btn btn-outline-secondary ms-2"
          type='button' onClick={handleButtonClick}>
          <span><i className="fas fa-file-image"></i></span></button>
        <button className="btn btn-outline-danger ms-2"
          type='button' onClick={cleanClick}>
          <span><i className="fas fa-times"></i></span></button>          
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleImageUpload}
          accept="image/*"
        />
      </div>
      <div style={{ display: stream ? 'none' : 'block', maxWidth: '100%' }}>
        <ImageComponent
          key={timestamp}
          base64img={Afoto}
          titulo={Atitulo}
          className={Aclasse}
          base64={false}
        />
      </div>
      <div className={`${Aclasse}`}>
        <video ref={videoRef} autoPlay={true} style={{ display: stream ? 'block' : 'none', maxWidth: '100%' }} />
      </div>
    </div>
  );
}

export default CAMERACOMPONENT;
