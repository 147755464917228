import './style.css';
import LOGO from '../../assets/logo-negativo.png';
import { Link, useNavigate } from 'react-router-dom';
import { getparceiro, getadmin } from 'functions/gettabconfig';
import { getNotificacao } from 'functions/getnotificacao';
import { useEffect, useState } from 'react';


function NAVBAR(props){
  const [logadop, setLogadop] = useState(false);
  const [logadoa, setLogadoa] = useState(false);
  const [temnotificacao, setTemNotificacao] = useState(0);

  const navigate = useNavigate();

  useEffect(() => { 
    const fetchData = async () => {
      try {
        const notificacoes = await getNotificacao();
        setTemNotificacao(notificacoes);
      } catch (error) {
        console.error('Erro ao buscar notificações:', error);
        setTemNotificacao(0);
      }
      setLogadop(getparceiro());
      setLogadoa(getadmin());
    };
    fetchData();
  }, []);

  const goNotificacao = () => {
    navigate('/notificacao');
  }

    return <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-custom">
  <div className="container-fluid">
    <a className="navbar-brand" href="#"><img src={LOGO} width={100}/></a>
   
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        </li>       
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Meu{localStorage.getItem('nome')}
          </a>
          <ul className="dropdown-menu">
            {/* <li><Link className="dropdown-item" to="/meu/home">Home</Link></li>
            <li><Link className="dropdown-item" to="/meu/extrato">Meu Extrato</Link></li>
            <li><Link className="dropdown-item" to="/meu/cupom">Meus Cupons</Link></li>            
            <li><Link className="dropdown-item" to="/meu/desconto">Meus Descontos</Link></li>
            <li><Link className="dropdown-item" to="/meu/conquista">Minhas Conquistas</Link></li> */}
            <li><Link className="dropdown-item" to="/login">Sair</Link></li>
          </ul>
        </li>
        {
          logadop ?          
          <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Parceiro
          </a>
          <ul className="dropdown-menu">
            {/* <li><Link className="dropdown-item" to="/parceiro/home">Home</Link></li> */}
            <li><Link className="dropdown-item" to="/parceiro/vender">Vender</Link></li>
            <li><Link className="dropdown-item" to="/admin/estabelecimento">Estabelecimento</Link></li>
            {/* <li><Link className="dropdown-item" to="/parceiro/desconto">Desconto</Link></li>
            <li><Link className="dropdown-item" to="/parceiro/cupom">Cupom</Link></li> */}
            <li><Link className="dropdown-item" to="/parceiro/login">Sair</Link></li>            
          </ul>
          </li>
          : null
 
          // <li className="nav-item">
          //   <Link className="nav-link active" to="/parceiro/login">Login Parceiro</Link>
          // </li>         
        }        
        {
          logadoa ?          
          <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Administrador
          </a>
          <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/admin/home">Home</Link></li>
            <li><Link className="dropdown-item" to="/admin/vender">Vender</Link></li>
            <li><Link className="dropdown-item" to="/admin/campanha">Campanha</Link></li>
            <li><Link className="dropdown-item" to="/admin/estabelecimento">Estabelecimento</Link></li>            
            <li><Link className="dropdown-item" to="/admin/contemplado">Contemplado</Link></li>
            <li><Link className="dropdown-item" to="/admin/notificacao">Notificação</Link></li>
            <li><Link className="dropdown-item" to="/admin/aviso">Avisos</Link></li>
            <li><Link className="dropdown-item" to="/admin/login">Sair</Link></li>            
          </ul>
          </li>
          :null
 
          // <li className="nav-item">
          //   <Link className="nav-link active" to="/admin/login">Login Admin</Link>
          // </li>         
        }        
      </ul>
      {/* <form className="d-flex" role="search">
       <input type="text" onChange={(e) => props.setESearch(e.target.value)} 
                         className="form-control me-1" id="floatinginputemail" placeholder="Parceiro"/>       
        <button className="btn btn-primary" type="button">Pesquisa</button>
      </form> */}
      {
        temnotificacao > 0 
           && <button type="button" class="btn btn-primary"
               onClick={()=>goNotificacao()}>
                                Notificações <span class="badge badge-light">{temnotificacao}</span>
              </button>           
      }
    
    </div>
  </div>
</nav>
}

export default NAVBAR;