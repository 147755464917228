import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NAVBAR from "components/navbar";
import config from "config";
import MESSAGE from "components/message";
import api from "services/api";
import DIALOGWAIT from "../../../dialog/dialogwait";
import RADIO from "components/radio";
import { Buffer } from 'buffer';
import CAMERACOMPONENT from "components/cameraimagem";
const { v4: uuidv4 } = require('uuid');

function AdminNotificacao() {
    const myRefreshToken = '';
    const [titulo, setTitulo] = useState('');
    const [corpo, setCorpo] = useState('');
    const [imagem, setImagem] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [loading, setLoading] = useState(false);
    const [tipoenvio, setTipoEnvio] = useState('N');
    const [todos, setTodos] = useState('I');

    const navigate = useNavigate();

    async function enviaMensagem() {
        const dados = {
            "type": "M",
            "title": titulo,
            "body": corpo,
            "image": `${config.BASEIMG}${imagem}`
        };

        setLoading(true);

        try {
            // Primeiro POST para notificação
            const postResponse = await api.post(`${config.NOTIFICACAO}?p=${tipoenvio}`, dados);

            if (postResponse.status === 202) {
                try {
                    // GET para obter tokens de registro
                    const getResponse = await api.get(`${config.PUSHNOTIFICATION}/registration?TODOS=${todos}`);

                    if (getResponse.status === 200) {
                        dados.registrationTokens = getResponse.data;

                        try {
                            const username = config.BASICAPI;
                            const password = config.BASICAPI;
                            const token = Buffer.from(`${username}:${password}`).toString('base64');
                            const headers = {
                                'Authorization': `Basic ${token}`,
                                'Content-Type': 'application/json'
                            };
                            // POST para enviar push notifications
                            const sendPushResponse = await axios.post(`https://horse.opnet.com.br${config.SENDPUSH}`, dados, { headers });

                            if (sendPushResponse.data.failedTokens !== null) {
                                // PATCH para lidar com tokens falhos
                                const patchDados = { 'failedTokens': sendPushResponse.data.failedTokens };

                                try {
                                    await api.patch(`${config.PUSHNOTIFICATION}/registration`, patchDados);
                                } catch (patchError) {
                                    console.error('Erro no PATCH:', patchError);
                                }
                            }

                            setLoading(false);
                            navigate('/');
                        } catch (sendPushError) {
                            console.error('Erro ao enviar push notification:', sendPushError);
                            setMensagem('Erro ao enviar push notification.');
                            setLoading(false);
                        }
                    } else {
                        throw new Error('Falha ao obter tokens de registro');
                    }
                } catch (getError) {
                    console.error('Erro no GET:', getError);
                    setMensagem('Erro ao buscar tokens de registro.');
                    setLoading(false);
                }
            } else {
                throw new Error(postResponse.response.data.erro || 'Erro desconhecido no POST inicial');
            }
        } catch (postError) {
            console.error('Erro no POST inicial:', postError);
            setMensagem(postError.message || 'Erro ao enviar a notificação.');
            setLoading(false);
        }
    }


    return <div>
        <NAVBAR />
        <div className='mt-page'>
            <MESSAGE msg={mensagem} />
            <form>
                <div className="row">
                    <div className="col-md-10">
                        <label htmlFor="forTitulo" className="form-label">Titulo</label>
                        <input type="text"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                            className="form-control" id="forTitulo" />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forCorpo" className="form-label">Mensagem</label>
                        <textarea type="text"
                            value={corpo}
                            onChange={(e) => setCorpo(e.target.value)}
                            className="form-control" id="forCorpo" />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forImagem" className="form-label">Link Imagem</label>
                        <CAMERACOMPONENT
                            heigth={200}
                            width={200}
                            foto={imagem}
                            setFoto={setImagem}
                            className={'foto-notificacao'}
                            base64={false}
                            //setImgB64={setB64Foto}
                            nomedoarquivo={`not${ uuidv4() }.png`}
                            diretorio={'imgban'}
                        />
                        <input type="text" value={imagem} disabled />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forTEnvio" className="form-label">Tipo envio</label>
                        <RADIO
                            chave={'forTEnvio'}
                            avalores={['N', 'P', 'I']}
                            alabel={['Todos', 'Parceiros', 'Eu']}
                            setValor={setTipoEnvio}
                            valor={tipoenvio}
                        />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forTODOS" className="form-label">Push</label>
                        <RADIO
                            chave={'forTODOS'}
                            avalores={['A', 'P', 'I']}
                            alabel={['Todos', 'Parceiros', 'Eu']}
                            setValor={setTodos}
                            valor={todos}
                        />
                    </div>

                    <div className="col-md-12 mt-5">
                        <button type="button" className="btn btn-info btn-large" onClick={(e) => enviaMensagem()}>Notificacao </button>
                    </div>
                </div>
            </form>
        </div>
        <DIALOGWAIT
            trigger={loading}
            setTrigger={setLoading}
        />
    </div>
}

export default AdminNotificacao;